import type { AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'
import type { IShareKeyData } from '~/stores/types/schema'

export default class SchemaService extends BaseService {
  fetchSchema = async (route: any): Promise<IFAQS> => {
    let url = '/api/v1/schema/?fmt=json'
    if (route.query.s !== undefined) {
      url = url + '&s=' + route.query.s
    }
    const response: AxiosResponse<IFAQS> = await this.http.get(url)
    return response?.data
  }

  generateShareUrl = async (): Promise<IShareKeyData> => {
    const response: AxiosResponse<IShareKeyData> = await this.http.get(
      '/api/v1/schema/generate_share_url/',
    )
    return response?.data
  }
}
