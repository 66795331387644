// import lunr from 'lunr'
import lunr from 'lunr'
import * as Sentry from '@sentry/vue'
import type {
  ICMSClosedClaimsTable,
  IShareKeyData,
  ITable,
  ITableField,
  IUserData,
} from './types/schema'
import SchemaService from '~/services/schema-service'

export const useSchemaStore = defineStore('schemaStore', () => {
  const schemaService = new SchemaService()

  // States
  const userData: Ref<IUserData | null> = ref(null)
  const userAuthenticated: Ref<Boolean> = ref(false)
  const schema: Ref<any> = ref(null)
  const validsharekey: Ref<string> = ref('')
  // Using any as lunr.Index can't be typed
  const searchTableLookup: Ref<any> = ref(null)
  // TODO: remove this. not in use
  const changelog: Ref<any> = ref(null)
  const schemaComingSoon: Ref<[] | null> = ref(null)
  const activeTable: Ref<ITable | null> = ref(null)
  const activeField: Ref<ITableField | null> = ref(null)
  const activeschema: Ref<string | null> = ref(null)
  const shareKeyData: Ref<IShareKeyData | null> = ref(null)
  // Adding an order is not necessary, ones without order will be added in the end
  const groupOrder: string[] = [
    'PATIENT READYDATA',
    'PROVIDER 360 READYDATA',
    'REFERENCE',
  ]
  const LoadingMessage: Ref<string> = ref(
    'Please wait while we authenticate you',
  )
  const copiedCode: Ref<string> = ref('')

  const subGroupOrder: Record<string, string[]> = {
    'PATIENT READYDATA': [
      'OPEN CLAIMS READYDATA',
      'MULTI-PAYER CLOSED CLAIMS READYDATA',
      'ALL MEDICARE CLOSED CLAIMS READYDATA',
      'ELECTRONIC HEALTH RECORDS',
      'OTHER',
    ],
    'PROVIDER 360 READYDATA': [
      'AFFILIATIONS',
      'ACCOUNT',
      'HCP',
      'CLINICAL TRIALS',
      'PUBLICATION',
      'OPEN PAYMENTS',
    ],
    REFERENCE: ['ENTITY', 'CROSSWALK', 'OTHER'],
  }

  const subgroupDescriptions: Record<string, string> = {
    PRIME_PLD:
      "McKesson Compile's enhanced Anonymous Patient Level Data (abbreviated as APLD or just PLD) is claims layout with integrated affiliations and smart cleanups to power your analytical projects",
  }
  const cmsClosedClaimsTable: ICMSClosedClaimsTable = {
    name: 'ALL MEDICARE CLOSED CLAIMS READYDATA',
    label: 'ALL_MEDICARE_CLOSED_CLAIMS_READYDATA',
    fields: [],
    is_view: false,
    relations: [],
    description:
      'Aggregated data summaries of 100% closed Medicare claims from Centers for Medicare and Medicaid Services (CMS). Transactional data is not available. Data must be summarized to the patient group of 10 or more.',
    table_count: 0,
    patients_count: '68M',
    last_updated: '',
    database_name: '',
    source_update_frequency: '',
    sfSchemaName: 'CMS',
  }
  const nodeOnlyTables: string[] = ['ALL MEDICARE CLOSED CLAIMS READYDATA']

  // Mutations

  const setUser = (payload: IUserData) => {
    userData.value = payload
  }

  const isUserAuthenticated = (payload: boolean) => {
    userAuthenticated.value = payload
  }

  const setValidShareKey = (payload: string) => {
    validsharekey.value = payload
  }
  const clearActives = () => {
    activeTable.value = null
    activeschema.value = null
    activeField.value = null
  }

  const setActives = (tableID: string) => {
    if (tableID) {
      if (tableID.split('.').length === 3) {
        activeField.value = schema.value.fieldlookup[tableID]
        tableID = tableID.split('.').slice(0, 2).join('.')
      } else {
        activeField.value = null
      }
      const schemaActive = schema.value.groupLookup[tableID]
      if (schemaActive) {
        activeTable.value = schema.value.tablelookup[tableID][0]
        activeschema.value = schemaActive
      } else {
        // Fall back to the first schema on wrong table name in the url
        const firstSchema = Object.keys(schema.value.tablegrouping)[0]
        setActiveSchema(firstSchema)
      }
    }
  }
  const setActiveSchema = (schemaValue: string) => {
    activeschema.value = schemaValue
    // TODO: Replace type any if possible
    const entries: any[] = Object.entries(
      schema.value.tablegrouping[schemaValue],
    )
    activeTable.value = entries[0][1][0]
    const currentPath = window.location.href.replace(window.location.origin, '')
    const hash =
      '#' + activeTable?.value?.sfSchemaName + '.' + activeTable?.value?.label
    let newRoute = ''
    if (window.location.hash) {
      newRoute = currentPath.substring(0, currentPath.indexOf('#')) + hash
    } else {
      newRoute = currentPath + hash
    }
    history.pushState({}, '', newRoute)
  }

  const setActiveField = (field: ITableField | null) => {
    activeField.value = field
  }

  const setSchemaMeta = (schemaMeta: any) => {
    const computedSchema = {
      tablelookup: null,
      tablegrouping: null,
      groupLookup: null,
      fieldlookup: null,
      schema_json: schemaMeta.schema,
      created: new Date(schemaMeta.created_at.split(' ')[0]),
    }
    schema.value = computedSchema

    const schemaJson = schema.value.schema_json
    const x = Object.assign({}, schemaJson)
    x.schema = schemaJson

    setSchema(x)
  }

  const setSchema = (schemaData: any) => {
    const computedSchema: any = Object.assign({}, schema)
    computedSchema.tablelookup = {}
    computedSchema.tablegrouping = {}
    computedSchema.groupLookup = {}
    computedSchema.fieldlookup = {}
    const searchData: { id: string; description: any; label: any }[] = []
    const actualGroups = Object.keys(schemaData.schema.schemas)
    const customOrder = groupOrder.filter(
      (v: string) => v in schemaData.schema.schemas,
    )
    const difference = actualGroups.filter((x) => !customOrder.includes(x))
    customOrder
      .concat(difference)
      .forEach((groupName: string, orderIndex: number) => {
        const groupData = schemaData.schema.schemas[groupName]
        // Adding All Medicare Closed Claims ReadyData under PATIENT READYDATA--> Others.
        // JIRA: CONS-5
        if (groupName === 'PATIENT READYDATA') {
          groupData['ALL MEDICARE CLOSED CLAIMS READYDATA'] = {
            CMS: {
              ALL_MEDICARE_CLOSED_CLAIMS_READYDATA: cmsClosedClaimsTable,
            },
          }
        }
        computedSchema.tablegrouping[groupName] = {}
        const actualSubGroups = Object.keys(groupData)
        const customSubGroupOrder = subGroupOrder[groupName].filter(
          (v: string) => v in groupData,
        )
        const difference = actualSubGroups.filter(
          (x) => !customSubGroupOrder.includes(x),
        )
        customSubGroupOrder
          .concat(difference)
          .forEach((subGroupName: string | number) => {
            const subGroupData = groupData[subGroupName]
            Object.entries(subGroupData).forEach((sfSchema) => {
              const [sfSchemaName, tables] = sfSchema
              Object.entries(tables).forEach((table) => {
                const tableData: ITable = table[1] as ITable
                // converting table and field descriptions to markdown
                if (
                  Object.prototype.hasOwnProperty.call(tableData, 'description')
                ) {
                  // eslint-disable-next-line no-self-assign
                  tableData.description = tableData.description
                }
                tableData.sfSchemaName = sfSchemaName
                tableData.is_view = false
                // table_data.hasOwnProperty('is_view') && table_data.is_view === true
                tableData.fields.forEach(
                  (field: {
                    hasOwnProperty: (arg0: string) => any
                    description: string | null
                    name: string
                  }) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        field,
                        'description',
                      ) &&
                      field.description !== '' &&
                      field.description !== null
                    ) {
                      // eslint-disable-next-line no-self-assign
                      field.description = field.description
                    }
                    computedSchema.fieldlookup[
                      sfSchemaName + '.' + tableData.label + '.' + field.name
                    ] = field
                  },
                )
                try {
                  computedSchema.tablegrouping[groupName][subGroupName].push(
                    tableData,
                  )
                } catch (err) {
                  computedSchema.tablegrouping[groupName][subGroupName] = [
                    tableData,
                  ]
                }
                computedSchema.tablelookup[
                  sfSchemaName + '.' + tableData.label
                ] = [tableData, orderIndex]
                computedSchema.groupLookup[
                  sfSchemaName + '.' + tableData.label
                ] = groupName

                // for schema wide search
                const searchDataobj = {
                  id: sfSchemaName + '.' + tableData.label,
                  description: tableData.description,
                  label: tableData.label,
                }
                searchData.push(searchDataobj)

                tableData.fields.forEach(
                  (field: { name: string; description: any }) => {
                    const searchDataobj = {
                      id:
                        sfSchemaName + '.' + tableData.label + '.' + field.name,
                      description: field.description,
                      label: field.name,
                    }
                    searchData.push(searchDataobj)
                  },
                )
              })
            })
          })
      })
    schema.value.tablelookup = computedSchema.tablelookup
    schema.value.tablegrouping = computedSchema.tablegrouping
    schema.value.groupLookup = computedSchema.groupLookup
    schema.value.fieldlookup = computedSchema.fieldlookup

    searchTableLookup.value = lunr(function (this: any) {
      this.ref('id')
      this.field('description')
      this.field('label')
      // eslint-disable-next-line import/no-named-as-default-member
      this.pipeline.remove(lunr.stemmer)

      searchData.forEach(function (this: any, doc) {
        this.add(doc)
      }, this)
    })
  }

  const setChangelog = (value: any) => {
    changelog.value = value
  }

  const setComingSoonSchema = (payload: any) => {
    schemaComingSoon.value = payload
  }
  const setLoadingMessage = (payload: any) => {
    LoadingMessage.value = payload
  }
  const setCopiedCode = (payload: string) => {
    copiedCode.value = payload
  }
  const setShareKeyData = (payload: IShareKeyData) => {
    shareKeyData.value = payload
  }
  // Actions
  const fetchSchemaData = async () => {
    const route = useRoute()
    if (userData.value === null) {
      await schemaService.fetchSchema(route).then(
        (response: any) => {
          if (response && response.user) {
            if (response.user === 'ShareKey') {
              isUserAuthenticated(true)
              setValidShareKey(route.query.s as string)
            } else {
              let name = response.user.name
              name =
                name !== null && name.length > 0 ? name : response.user.username
              const userObject = Object.assign({}, response.user, { name })
              setUser(userObject)
              isUserAuthenticated(true)
              Sentry.setUser({ email: userObject.email })
              Sentry.setContext('userinfo', {
                hashid: userObject.hashid,
              })
            }
            setSchemaMeta(response)
          } else {
            const errorMessage =
              'The link has expired. Please contact your sales representative or email  <a href="mailto:compile_sales@mckesson.com" class="alert-link">compile_sales@mckesson.com</a> for a new link.'
            throw createError({
              statusCode: 500,
              statusMessage: errorMessage,
              fatal: true,
            })
          }
        },
        (_err: Error) => {
          // Error message on invalid share key.
          if (route.query.s) {
            const errorMessage =
              'The link has expired. Please contact your sales representative or email  <a href="mailto:compile_sales@mckesson.com" class="alert-link">compile_sales@mckesson.com</a> for a new link.'
            throw createError({
              statusCode: 500,
              statusMessage: errorMessage,
              fatal: true,
            })
          }
        },
      )
    }
  }

  const generateShareUrl = async () => {
    await schemaService.generateShareUrl().then((response: IShareKeyData) => {
      setShareKeyData(response)
    })
  }

  return {
    userData,
    schema,
    schemaComingSoon,
    userAuthenticated,
    activeschema,
    activeTable,
    activeField,
    nodeOnlyTables,
    subgroupDescriptions,
    copiedCode,
    shareKeyData,
    validsharekey,
    searchTableLookup,
    setUser,
    isUserAuthenticated,
    clearActives,
    setActives,
    setActiveSchema,
    setActiveField,
    setSchemaMeta,
    setSchema,
    setChangelog,
    setComingSoonSchema,
    setLoadingMessage,
    setCopiedCode,
    fetchSchemaData,
    generateShareUrl,
  }
})
